<template>
  <div>
    <div
      class="d-flex"
    >
      <v-select
        v-model="user.phoneCountryCode"
        :items="countries"
        :filled="filled"
        :dense="dense"
        hide-details
        :label="countryCodePlaceholder"
        class="pr-1"
        :disabled="disabled"
        :item-text="getItemText"
        :menu-props="{ offsetY: true }"
        item-value="areaCode"
        :outlined="outlined"
        :rules="required ? [validators.required] : []"
        :style="{
          flex: 0,
        }"
      >
        <template
          v-slot:selection="{ item }"
        >
          {{ item.areaCode }}
        </template>
      </v-select>

      <v-text-field
        v-model="user.nationalPhoneNumber"
        v-mask="'## ### ####'"
        :disabled="disabled"
        :filled="filled"
        :dense="dense"
        :label="phoneNumberPlaceholder"
        class="pl-1"
        hide-details
        type="tel"
        :outlined="outlined"
        :rules="required ? [validators.required] : []"
      />
    </div>

    <p
      v-if="hasPhoneNumber && !disabled"
      class="mt-2 ml-2 mb-0 text--secondary caption"
    >
      {{ formattedNumber }}
    </p>
  </div>
</template>

<script>
  import { mask } from 'vue-the-mask';
  import getValidators from '@/mixins/validators';

  export default {
    directives: {
      mask,
    },

    props: {
      user: {
        type: Object,
        required: true,
      },

      disabled: {
        type: Boolean,
        default: false,
      },

      required: {
        type: Boolean,
        default: false,
      },

      outlined: {
        type: Boolean,
        default: false,
      },

      filled: {
        type: Boolean,
        default: false,
      },

      dense: {
        type: Boolean,
        default: false,
      }
    },

    data: () => ({
      ...getValidators('required', 'requiredArray', 'email'),
    }),

    computed: {
      countries() {
        return [
          {
            label: 'Suomi',
            areaCode: '+358',
          },
          {
            label: 'Eesti',
            areaCode: '+372',
          },
        ];
      },

      formattedNumber() {
        return `${this.user.phoneCountryCode} ${this.user.nationalPhoneNumber}`;
      },

      hasPhoneNumber() {
        return this.user.nationalPhoneNumber && this.user.nationalPhoneNumber.length > 0;
      },

      countryCodePlaceholder() {
        if (this.disabled) {
          return null;
        }

        return this.$pgettext('MPhoneInput component select input placeholder', 'Country code');
      },

      phoneNumberPlaceholder() {
        if (this.disabled) {
          return null;
        }

        return this.$pgettext('MPhoneInput component text input placeholder', 'Phone number');
      },
    },

    watch: {
      user() {
        this.setDefaultAreaCode();
      },
    },

    created() {
      this.setDefaultAreaCode();
    },

    methods: {
      getItemText({ label, areaCode }) {
        return `${areaCode} (${label})`;
      },

      setDefaultAreaCode() {
        if (this.user.phoneCountryCode) return;
        this.user.phoneCountryCode = this.countries[0].areaCode; // eslint-disable-line
      },
    },
  };
</script>
